{
  "loading": "Chargement…",
  "menu": {
    "home": "Accueil",
    "settings": "Paramètres",
    "logout": "Déconnexion"
  },
  "home": {
    "title": "Mes notes de frais"
  },
  "actions": {
    "new": "Nouvelle note de frais",
    "save": "Sauvegarder cette note de frais",
    "edit": "Modifier cette note de frais",
    "send": "Envoyer cette note de frais",
    "can_send": "Une fois votre note de frais terminée, cliquez ci-dessous pour l'envoyer pour approbation."
  },
  "state": {
    "empty": {
      "title": "Aucune note de frais trouvée",
      "description": "Il semblerait qu'il n'y a aucune note de frais liée à votre adresse email. Créez-en une à l'aide du bouton 'Nouvelle note de frais' !"
    }
  },
  "form": {
    "approved_by": {
      "label": "Approuvé par",
      "hint": "Veuillez préciser qui ou quel service a approuvé cette note de frais"
    },
    "description": {
      "label": "Description",
      "hint": "Veuillez décrire votre note de frais. Ajouter les date et les informations importantes"
    },
    "description_required": "Une description est requise.",
    "approved_by_required": "Le champ approuvé par est requis.",
    "updated": "La note de frais a été mise à jour",
    "not_found": "Note de frais non trouvée",
    "locked": {
      "title": "Note de frais envoyé",
      "text": "Votre note de frais a été envoyé, vous ne pouvez plus la modifier. Si vous avez fait une erreur, veuillez contacter la personne en charge des notes de frais."
    }
  },
  "purchases": {
    "title": "Achats",
    "new": "Nouvel achat",
    "date": {
      "label": "Date",
      "hint": "Veuillez préciser la date d'achat",
      "required": "Une date est requise."
    },
    "type": {
      "label": "Type",
      "hint": "Veuillez préciser le type d'achat. Ex : Transport, Repas",
      "required": "Un type est requis."
    },
    "amount": {
      "label": "Montant",
      "hint": "Indiquez le montant payé",
      "required": "Un montant est requis."
    },
    "description": {
      "label": "Description",
      "hint": "Veuillez décrire votre achat. Pourquoi il a été fait…",
      "required": "Une description est requise."
    },
    "document": {
      "label": "Document",
      "hint": "Veuillez mettre en ligne une preuve d'achat. Ex : facture, ticket de caisse…",
      "required": "Un document est requis."
    },
    "actions": {
      "label": "Actions"
    },
    "close": "Fermer",
    "save": "Sauvegarder l'achat",
    "delete_title": "Êtes-vous sûr ?",
    "delete_text": "Vous ne pourrez pas récupérer cet achat.",
    "delete_yes": "Oui, supprimer cet achat",
    "delete_no": "Non",
    "current_document": "Actuellement : "
  },
  "login": {
    "title": "Connexion",
    "subtitle": "Connectez-vous sur votre tableau de bord des notes de frais.",
    "email": "Votre adresse email",
    "login": "Connexion",
    "email_required": "Une adresse email est requise.",
    "success": {
      "title": "Email envoyé !",
      "description": "Vous allez bientôt recevoir un email avec un lien de connexion."
    },
    "error": {
      "title": "Une erreur est survenue.",
      "description": "Oups… Essayez de rafraichir la page. Si le problème persiste, contacter votre service informatique.",
      "no_provider": "Oups… Aucun fournisseur d'authentification a été configuré par votre administrateur système…"
    },
    "using": "Se connecter avec {provider}"
  },
  "welcome": {
    "title": "Bienvenue sur votre tableau de bord des notes de frais \uD83C\uDF89 !",
    "text": "Bienvenue sur votre tableau de bord des notes de frais. Ceci est la première fois que vous vous connectez, veuillez remplir le formulaire suivant afin de terminer la création de votre compte. Vous allez pouvoir modifier ces informations plus tard."
  },
  "settings": {
    "first_name": "Prénom",
    "last_name": "Nom de famille",
    "bank_account": "Informations bancaires",
    "iban": "IBAN",
    "bic": "BIC",
    "save": "Sauvegarder",
    "first_name_required": "Votre prénom est requis.",
    "last_name_required": "Votre nom de famille est requis.",
    "iban_required": "Votre IBAN est requis.",
    "bic_required": "Votre BIC est requis.",
    "updated": "Vos paramètres ont bien été sauvegardés",
    "error": "Une erreur est survenue lors de la mise à jour de vos paramètres",
    "title": "Mes paramètres",
    "language": "Sélection de la langue",
    "lang": {
      "en": "\uD83C\uDDEC\uD83C\uDDE7 Anglais",
      "fr": "\uD83C\uDDEB\uD83C\uDDF7 Français"
    }
  },
  "api": {
    "no_response": "Aucune réponse de l'api…",
    "document_not_found": "Document non trouvé…"
  },
  "fields": {
    "id": "ID",
    "description": "Description",
    "status": "Status",
    "amount": "Montant",
    "date": "Date"
  },
  "status": {
    "pending": "En attente",
    "generated": "Généré",
    "draft": "Brouillon",
    "sent": "Envoyé",
    "viewed": "Vue",
    "approved": "Approuvé",
    "partial": "Payé en partie",
    "paid": "Payé",
    "received": "Reçu",
    "cancelled": "Annulé",
    "voided": "Annulé (après envoie)",
    "denied": "Refusé",
    "refunded": "Remboursé"
  }
}